import React from "react";
import { Button } from "../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../components/ui/card";

import { modules } from "../data/learnToEarn";

function LearnToEarn() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-[#E1B6FF] mb-4">
        Learn-to-Earn: Empower Yourself in the World of Crypto Prediction
        Markets
      </h1>
      <p className="text-xl text-white mb-8">
        Welcome to the Future of Learning and Earning!
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
        <Card className="bg-black/40 border-white/10">
          <CardHeader>
            <CardTitle className="text-2xl font-bold text-white">
              Why Learn-to-Earn?
            </CardTitle>
          </CardHeader>
          <CardContent className="text-white/80">
            <ul className="list-disc list-inside space-y-2">
              <li>
                Earn While You Learn: Every completed lesson or quiz earns you
                tokens.
              </li>
              <li>
                No Risk, Only Rewards: Practice predicting with zero financial
                risk in our simulated environment.
              </li>
              <li>
                Boost Your Skills: Understand market trends, analyze data, and
                make informed predictions.
              </li>
              <li>
                Join a Thriving Community: Be part of a global network of
                learners and predictors.
              </li>
            </ul>
          </CardContent>
        </Card>

        <Card className="bg-black/40 border-white/10">
          <CardHeader>
            <CardTitle className="text-2xl font-bold text-white">
              How It Works
            </CardTitle>
          </CardHeader>
          <CardContent className="text-white/80">
            <ol className="list-decimal list-inside space-y-2">
              <li>
                Learn the Basics: Start with easy-to-understand tutorials about
                blockchain, prediction markets, and crypto fundamentals.
              </li>
              <li>
                Take Quizzes: Test your knowledge and earn tokens for every
                correct answer.
              </li>
              <li>
                Simulated Predictions: Apply what you've learned in risk-free
                simulated predictions.
              </li>
              <li>
                Earn and Use Rewards: Use your tokens to participate in real
                predictions or withdraw them for other uses.
              </li>
            </ol>
          </CardContent>
        </Card>
      </div>

      <h2 className="text-3xl font-bold text-[#E1B6FF] mb-6">
        What You'll Learn
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
        {modules.map((module, index) => (
          <Card key={index} className="bg-black/40 border-white/10">
            <CardHeader>
              <CardTitle className="text-xl font-bold text-white flex items-center">
                <module.icon className="mr-2 h-6 w-6" />
                Module {index + 1}: {module.title}
              </CardTitle>
            </CardHeader>
            <CardContent className="text-white/80">
              <ul className="list-disc list-inside space-y-2">
                {module.items.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </CardContent>
          </Card>
        ))}
      </div>

      <Card className="bg-black/40 border-white/10 mb-12">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-white">
            How to Get Started
          </CardTitle>
        </CardHeader>
        <CardContent className="text-white/80">
          <ol className="list-decimal list-inside space-y-2">
            <li>
              Sign Up for Free: Create your account to unlock access to all
              tutorials.
            </li>
            <li>
              Start Learning: Dive into bite-sized modules at your own pace.
            </li>
            <li>
              Earn Rewards: Complete quizzes and simulations to earn tokens.
            </li>
            <li>
              Predict and Win: Use your earned tokens to make real-world
              predictions and win even more!
            </li>
          </ol>
        </CardContent>
      </Card>

      <Card className="bg-black/40 border-white/10 mb-12">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-white">
            Your Rewards Await
          </CardTitle>
        </CardHeader>
        <CardContent className="text-white/80">
          <ul className="list-disc list-inside space-y-2">
            <li>
              Tokens: Redeemable in our prediction market or for other features.
            </li>
            <li>
              Exclusive NFTs: Earn unique digital collectibles for milestone
              achievements.
            </li>
            <li>
              Fee Discounts: Get reduced fees for participating in predictions.
            </li>
          </ul>
        </CardContent>
      </Card>

      <Card className="bg-black/40 border-white/10 mb-12">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-white">
            What Our Learners Say
          </CardTitle>
        </CardHeader>
        <CardContent className="text-white/80 space-y-4">
          <blockquote className="border-l-4 border-[#E1B6FF] pl-4 italic">
            "I had no idea about blockchain or prediction markets, but this
            tutorial made it easy to understand. Plus, earning tokens while
            learning kept me motivated!"
            <footer className="text-right">— Sarah, New York</footer>
          </blockquote>
          <blockquote className="border-l-4 border-[#E1B6FF] pl-4 italic">
            "The quizzes were fun, and I used my earned tokens to make my first
            prediction. I'm hooked!"
            <footer className="text-right">— Raj, India</footer>
          </blockquote>
        </CardContent>
      </Card>

      <div className="text-center">
        <h2 className="text-3xl font-bold text-[#E1B6FF] mb-4">
          Join Us Today!
        </h2>
        <p className="text-white mb-6">
          Don't just watch the future of finance pass you by—be a part of it.
          Start your Learn-to-Earn journey now and unlock the knowledge and
          rewards that await.
        </p>
        <Button className="bg-[#E1B6FF] text-black hover:bg-[#E1B6FF]/80 text-lg px-8 py-3">
          Start Learning Now
        </Button>
      </div>

      <p className="text-white/60 text-center mt-8">
        Have questions? Visit our FAQ or reach out to our support team!
      </p>
    </div>
  );
}

export default LearnToEarn;
