import React, { useState, useEffect } from "react";
import {
  Activity,
  ArrowUpRight,
  ArrowDownRight,
  DollarSign,
  Clock,
  Loader,
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

const generateMockTransaction = () => {
  const types = ["buy", "sell"];
  const markets = [
    "Bitcoin Price 2024",
    "Ethereum Merge Success",
    "Chromia Adoption Rate",
    "US Election 2024",
    "FIFA World Cup 2026",
    "SpaceX Mars Mission",
    "Apple Stock Price",
    "Climate Change Impact",
    "Quantum Computing Breakthrough",
    "AI Regulation Passage",
  ];
  const names = [
    "Alice",
    "Bob",
    "Charlie",
    "David",
    "Eve",
    "Frank",
    "Grace",
    "Henry",
    "Ivy",
    "Jack",
    "Kate",
    "Liam",
    "Mia",
    "Noah",
    "Olivia",
    "Paul",
    "Quinn",
    "Rachel",
    "Sam",
    "Tara",
  ];

  return {
    id: Math.random().toString(36).substr(2, 9),
    type: types[Math.floor(Math.random() * types.length)],
    amount: parseFloat((Math.random() * 1000).toFixed(2)),
    market: markets[Math.floor(Math.random() * markets.length)],
    user: names[Math.floor(Math.random() * names.length)],
    timestamp: new Date(),
  };
};

function LiveUpdates() {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTransactions((prevTransactions) => {
        const newTransaction = generateMockTransaction();
        const updatedTransactions = [
          newTransaction,
          ...prevTransactions.slice(0, 6),
        ];
        return updatedTransactions;
      });
    }, 2000); // Generate a new transaction every 2 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-[#E1B6FF] mb-6 flex items-center">
        <Activity className="mr-2" />
        Live Updates
      </h1>
      <div className="bg-black/40 rounded-lg p-4 shadow-lg overflow-hidden flex-grow">
        {transactions.length === 0 ? (
          <div className="flex justify-center items-center h-64">
            <Loader className="w-8 h-8 text-[#E1B6FF] animate-spin" />
          </div>
        ) : (
          <div className="flex-grow max-h-[calc(100vh-12rem)] overflow-y-auto">
            <AnimatePresence initial={true}>
              {transactions.map((transaction, index) => (
                <motion.div
                  key={transaction.id}
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                  className={`flex items-center justify-between p-4 ${
                    index % 2 === 0 ? "bg-white/5" : "bg-transparent"
                  } rounded-lg mb-2`}
                >
                  <div className="flex items-center">
                    <div
                      className={`p-2 rounded-full ${
                        transaction.type === "buy"
                          ? "bg-green-500"
                          : "bg-red-500"
                      } mr-4`}
                    >
                      {transaction.type === "buy" ? (
                        <ArrowUpRight className="w-4 h-4 text-white" />
                      ) : (
                        <ArrowDownRight className="w-4 h-4 text-white" />
                      )}
                    </div>
                    <div>
                      <p className="font-semibold text-white">
                        {transaction.market}
                      </p>
                      <p className="text-sm text-gray-400">
                        {transaction.user}
                      </p>
                    </div>
                  </div>
                  <div className="text-right">
                    <p className="font-semibold text-white flex items-center">
                      <DollarSign className="w-4 h-4 mr-1" />
                      {transaction.amount.toFixed(2)}
                    </p>
                    <p className="text-sm text-gray-400 flex items-center">
                      <Clock className="w-3 h-3 mr-1" />
                      {transaction.timestamp.toLocaleTimeString()}
                    </p>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        )}
      </div>
    </div>
  );
}

export default LiveUpdates;
