import React, { useState, useEffect } from "react";
import { Button } from "./ui/button";
import { ethers } from "ethers";

export function WalletConnect() {
  const [account, setAccount] = useState(null);
  const [chainId, setChainId] = useState(null);

  useEffect(() => {
    checkConnection();
  }, []);

  const checkConnection = async () => {
    if (typeof window.ethereum !== "undefined") {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (accounts.length > 0) {
          setAccount(accounts[0]);
          const chainId = await window.ethereum.request({
            method: "eth_chainId",
          });
          setChainId(chainId);
        }
      } catch (error) {
        console.error(
          "An error occurred while checking the connection:",
          error
        );
      }
    }
  };

  const connectWallet = async () => {
    if (typeof window.ethereum !== "undefined") {
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        setAccount(address);
        const chainId = await window.ethereum.request({
          method: "eth_chainId",
        });
        setChainId(chainId);
      } catch (error) {
        console.error("An error occurred while connecting the wallet:", error);
      }
    } else {
      alert("Please install MetaMask!");
    }
  };

  const disconnectWallet = () => {
    setAccount(null);
    setChainId(null);
  };

  const switchToChromia = async () => {
    const chromiaChainId = "0x1234"; // Replace with actual Chromia chain ID
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chromiaChainId }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: chromiaChainId,
                chainName: "Chromia",
                nativeCurrency: {
                  name: "Chromia",
                  symbol: "CHR",
                  decimals: 18,
                },
                rpcUrls: ["https://rpc.chromia.com"], // Replace with actual Chromia RPC URL
                blockExplorerUrls: ["https://explorer.chromia.com"], // Replace with actual Chromia explorer URL
              },
            ],
          });
        } catch (addError) {
          console.error(
            "An error occurred while adding the Chromia network:",
            addError
          );
        }
      }
    }
  };

  return (
    <div>
      {!account ? (
        <Button
          onClick={connectWallet}
          className="border-[#E1B6FF] bg-[#E1B6FF] text-black hover:border-[#E1B6FF] hover:bg-[#E1B6FF] hover:text-black transition-colors duration-200"
        >
          Connect Wallet
        </Button>
      ) : (
        <div className="flex items-center gap-2">
          <span className="text-white text-sm truncate w-28">
            {account.slice(0, 6)}...{account.slice(-4)}
          </span>
          {chainId !== "0x1234" && ( // Replace '0x1234' with actual Chromia chain ID
            <Button onClick={switchToChromia} className="text-xs px-2 py-1">
              Switch to Chromia
            </Button>
          )}
          <Button
            onClick={disconnectWallet}
            variant="ghost"
            className="text-white hover:bg-[#E1B6FF]/10"
          >
            Disconnect
          </Button>
        </div>
      )}
    </div>
  );
}

export default WalletConnect;
