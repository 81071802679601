import React from "react";
import { Link } from "react-router-dom";
import { Search, Trophy, BookOpen, Activity } from "lucide-react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { WalletConnect } from "./WalletConnect";

export function Header() {
  return (
    <header className="border-b border-white/10 bg-black/40 backdrop-blur-sm">
      <div className="container mx-auto px-4">
        <div className="flex h-16 items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <Link to="/" className="text-2xl font-bold text-white">
              <img
                src={"assets/logo.jpeg"}
                alt="Crowmarket logo"
                style={{ height: "45px" }}
              />
            </Link>
          </div>
          <div className="flex items-center gap-2">
            <div className="relative hidden md:block">
              <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-white/50" />
              <Input
                type="search"
                placeholder="Search markets"
                className="w-[200px] pl-8 bg-white/5 border-white/10 text-white placeholder:text-white/50"
              />
            </div>
            <Link to="/learn-to-earn">
              <Button variant="ghost" className="text-white bg-black/40">
                <BookOpen className="w-4 h-4 mr-2" />
                Learn-to-Earn
              </Button>
            </Link>
            <Link to="/leaderboard">
              <Button variant="ghost" className="text-white bg-black/40">
                <Trophy className="w-4 h-4 mr-2" />
                Leaderboard
              </Button>
            </Link>
            <Link to="/live-updates">
              <Button variant="ghost" className="text-white bg-black/40">
                <Activity className="w-4 h-4 mr-2" />
                Live Updates
              </Button>
            </Link>
            <Button variant="ghost" className="text-white bg-black/40">
              Log In
            </Button>
            <Button variant="ghost" className="text-white bg-black/40">
              Sign Up
            </Button>
            <WalletConnect />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
