import React, { useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Progress } from "./ui/progress";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { ArrowUpIcon, ArrowDownIcon } from "lucide-react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import { ChartContainer, ChartTooltip, ChartTooltipContent } from "./ui/chart";

const dummyChartData = [
  { name: "Jan", yes: 40, no: 60 },
  { name: "Feb", yes: 45, no: 55 },
  { name: "Mar", yes: 55, no: 45 },
  { name: "Apr", yes: 60, no: 40 },
  { name: "May", yes: 65, no: 35 },
  { name: "Jun", yes: 70, no: 30 },
];

function PredictionDetail({
  id,
  title,
  category,
  yesPercentage,
  volume,
  endDate,
  description,
  trades,
  icon: Icon,
  sentimentData,
  volumeData,
}) {
  const [bidAmount, setBidAmount] = useState("");
  const [bidType, setBidType] = useState(null);
  const [timeRange, setTimeRange] = useState("7d");

  const filterDataByTimeRange = (data, range) => {
    const days = range === "7d" ? 7 : range === "30d" ? 30 : 90;
    return data.slice(-days);
  };

  const handleBid = (type) => {
    setBidType(type);
    // Here you would typically interact with a smart contract or API
    console.log(`User bid ${bidAmount} on ${type}`);
  };

  return (
    <div className="space-y-8">
      <div className="flex items-center space-x-4">
        <div className="p-4 rounded-full bg-[#E1B6FF]">
          <Icon className="w-12 h-12 text-black" />
        </div>
        <h1 className="text-3xl font-bold text-white">{title}</h1>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <Card className="lg:col-span-2 bg-black/40 border-white/10">
          <CardHeader>
            <CardTitle className="text-xl font-bold text-white">
              Prediction Chart
            </CardTitle>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={dummyChartData}>
                <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                <XAxis dataKey="name" stroke="#888" />
                <YAxis stroke="#888" />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="yes"
                  stroke="#E1B6FF"
                  strokeWidth={2}
                />
                <Line
                  type="monotone"
                  dataKey="no"
                  stroke="#FF6B6B"
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        <Card className="bg-black/40 border-white/10">
          <CardHeader>
            <CardTitle className="text-xl font-bold text-white">
              Place Your Bid
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <div>
                <div className="flex justify-between text-sm mb-2">
                  <span className="text-white/80">Yes</span>
                  <span className="text-white font-medium">
                    {yesPercentage}%
                  </span>
                </div>
                <Progress
                  value={yesPercentage}
                  className="bg-white/10"
                  indicatorClassName="bg-[#E1B6FF]"
                />
                <div className="flex justify-between text-sm mt-2">
                  <span className="text-white/80">No</span>
                  <span className="text-white font-medium">
                    {100 - yesPercentage}%
                  </span>
                </div>
              </div>
              <Input
                type="number"
                placeholder="Enter bid amount"
                value={bidAmount}
                onChange={(e) => setBidAmount(e.target.value)}
                className="bg-white/5 border-white/10 text-white"
              />
              <div className="grid grid-cols-2 gap-4">
                <Button
                  className="bg-[#E1B6FF] text-black hover:bg-[#E1B6FF]/80"
                  onClick={() => handleBid("yes")}
                >
                  Bid Yes
                </Button>
                <Button
                  className="bg-[#FF6B6B] text-black hover:bg-[#FF6B6B]/80"
                  onClick={() => handleBid("no")}
                >
                  Bid No
                </Button>
              </div>
              <div className="text-sm text-white/60">
                <p>Volume: {volume}</p>
                <p>Ends: {endDate.toLocaleDateString()}</p>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>

      <Card className="bg-black/40 border-white/10">
        <CardHeader>
          <CardTitle className="text-xl font-bold text-white">
            Description
          </CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-white/80">{description}</p>
        </CardContent>
      </Card>

      <Card className="bg-black/40 border-white/10">
        <CardHeader>
          <CardTitle className="text-xl font-bold text-white">
            Recent Trades
          </CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="text-white">Type</TableHead>
                <TableHead className="text-white">Amount</TableHead>
                <TableHead className="text-white">Price</TableHead>
                <TableHead className="text-white">Time</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {trades.map((trade) => (
                <TableRow key={trade.id}>
                  <TableCell>
                    {trade.type === "buy" ? (
                      <ArrowUpIcon className="text-green-500" />
                    ) : (
                      <ArrowDownIcon className="text-red-500" />
                    )}
                  </TableCell>
                  <TableCell className="text-white">{trade.amount}</TableCell>
                  <TableCell className="text-white">{trade.price}</TableCell>
                  <TableCell className="text-white">
                    {trade.timestamp}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-8">
        <Card className="bg-black/40 border-white/10">
          <CardHeader>
            <CardTitle className="text-xl font-bold text-white">
              Market Sentiment Analysis
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="mb-4">
              <Button
                onClick={() => setTimeRange("7d")}
                variant={timeRange === "7d" ? "default" : "outline"}
                className="mr-2"
              >
                7D
              </Button>
              <Button
                onClick={() => setTimeRange("30d")}
                variant={timeRange === "30d" ? "default" : "outline"}
                className="mr-2"
              >
                30D
              </Button>
              <Button
                onClick={() => setTimeRange("90d")}
                variant={timeRange === "90d" ? "default" : "outline"}
              >
                90D
              </Button>
            </div>
            <ChartContainer
              config={{
                bullish: {
                  label: "Bullish",
                  color: "#4ade80",
                },
                bearish: {
                  label: "Bearish",
                  color: "#f87171",
                },
                neutral: {
                  label: "Neutral",
                  color: "#60a5fa",
                },
              }}
              className="h-[300px]"
            >
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={filterDataByTimeRange(sentimentData, timeRange)}
                >
                  <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                  <XAxis dataKey="date" stroke="#888" />
                  <YAxis stroke="#888" />
                  <ChartTooltip content={<ChartTooltipContent />} />
                  <Bar
                    dataKey="bullish"
                    stackId="a"
                    fill="var(--color-bullish)"
                  />
                  <Bar
                    dataKey="bearish"
                    stackId="a"
                    fill="var(--color-bearish)"
                  />
                  <Bar
                    dataKey="neutral"
                    stackId="a"
                    fill="var(--color-neutral)"
                  />
                </BarChart>
              </ResponsiveContainer>
            </ChartContainer>
          </CardContent>
        </Card>

        <Card className="bg-black/40 border-white/10">
          <CardHeader>
            <CardTitle className="text-xl font-bold text-white">
              Trading Volume Trends
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="mb-4">
              <Button
                onClick={() => setTimeRange("7d")}
                variant={timeRange === "7d" ? "default" : "outline"}
                className="mr-2"
              >
                7D
              </Button>
              <Button
                onClick={() => setTimeRange("30d")}
                variant={timeRange === "30d" ? "default" : "outline"}
                className="mr-2"
              >
                30D
              </Button>
              <Button
                onClick={() => setTimeRange("90d")}
                variant={timeRange === "90d" ? "default" : "outline"}
              >
                90D
              </Button>
            </div>
            <ChartContainer
              config={{
                volume: {
                  label: "Volume",
                  color: "#e879f9",
                },
              }}
              className="h-[300px]"
            >
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={filterDataByTimeRange(volumeData, timeRange)}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                  <XAxis dataKey="date" stroke="#888" />
                  <YAxis stroke="#888" />
                  <ChartTooltip content={<ChartTooltipContent />} />
                  <Line
                    type="monotone"
                    dataKey="volume"
                    stroke="var(--color-volume)"
                    strokeWidth={3}
                  />
                </LineChart>
              </ResponsiveContainer>
            </ChartContainer>
          </CardContent>
        </Card>
      </div>

      <Card className="lg:col-span-2 bg-black/40 border-white/10">
        <CardHeader>
          <CardTitle className="text-xl font-bold text-white">
            Understanding the Analytics
          </CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-white/80 mb-4">
            Our advanced analytics tools provide deeper insights into market
            behavior and trends:
          </p>
          <ul className="list-disc list-inside text-white/80 space-y-2">
            <li>
              <strong>Market Sentiment Analysis:</strong> This chart shows the
              distribution of bullish (positive), bearish (negative), and
              neutral sentiment among market participants over time. It can help
              you gauge the overall mood of the market.
            </li>
            <li>
              <strong>Trading Volume Trends:</strong> This chart displays the
              daily trading volume over time. Higher volumes often indicate
              increased interest and activity in the market, which can be
              associated with significant price movements or important events.
            </li>
          </ul>
          <p className="text-white/80 mt-4">
            Use these tools to inform your trading decisions, but remember that
            past performance doesn't guarantee future results. Always conduct
            your own research and consider your risk tolerance before trading.
          </p>
        </CardContent>
      </Card>
    </div>
  );
}

export default PredictionDetail;
