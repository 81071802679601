import React, { useState } from "react";
import {
  Trophy,
  ArrowUp,
  ArrowDown,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";

// Mock data for the leaderboard
const leaderboardData = [
  { rank: 1, username: "CryptoKing", profit: 125000, winRate: 68, trades: 342 },
  {
    rank: 2,
    username: "BlockchainQueen",
    profit: 98000,
    winRate: 65,
    trades: 287,
  },
  {
    rank: 3,
    username: "SatoshiDisciple",
    profit: 87500,
    winRate: 62,
    trades: 401,
  },
  { rank: 4, username: "HODLer4Life", profit: 76000, winRate: 59, trades: 256 },
  { rank: 5, username: "DeFiWizard", profit: 68000, winRate: 57, trades: 312 },
  { rank: 6, username: "TokenMaster", profit: 62000, winRate: 55, trades: 298 },
  {
    rank: 7,
    username: "CoinCollector",
    profit: 57000,
    winRate: 54,
    trades: 275,
  },
  {
    rank: 8,
    username: "ChartChampion",
    profit: 52000,
    winRate: 53,
    trades: 331,
  },
  {
    rank: 9,
    username: "BullMarketBoss",
    profit: 48000,
    winRate: 52,
    trades: 289,
  },
  {
    rank: 10,
    username: "AltcoinAddict",
    profit: 45000,
    winRate: 51,
    trades: 302,
  },
];

export default function LeaderboardPage() {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const filteredData = leaderboardData.filter((user) =>
    user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-[#E1B6FF] mb-8 flex items-center">
        <Trophy className="mr-2" /> Leaderboard
      </h1>
      <div className="mb-4">
        <Input
          type="text"
          placeholder="Search by username"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="max-w-sm bg-black/40 border-white/10 text-white"
        />
      </div>
      <div className="bg-black/40 rounded-lg overflow-hidden">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-[#E1B6FF]">Rank</TableHead>
              <TableHead className="text-[#E1B6FF]">Username</TableHead>
              <TableHead className="text-[#E1B6FF]">Profit</TableHead>
              <TableHead className="text-[#E1B6FF]">Win Rate</TableHead>
              <TableHead className="text-[#E1B6FF]">Trades</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {paginatedData.map((user) => (
              <TableRow key={user.rank}>
                <TableCell className="font-medium text-white">
                  {user.rank}
                </TableCell>
                <TableCell className="text-white">{user.username}</TableCell>
                <TableCell className="text-white">
                  ${user.profit.toLocaleString()}
                  {user.rank <= 3 && (
                    <span className="ml-2 text-green-500">
                      <ArrowUp className="inline w-4 h-4" />
                    </span>
                  )}
                  {user.rank > 7 && (
                    <span className="ml-2 text-red-500">
                      <ArrowDown className="inline w-4 h-4" />
                    </span>
                  )}
                </TableCell>
                <TableCell className="text-white">{user.winRate}%</TableCell>
                <TableCell className="text-white">{user.trades}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className="mt-4 flex justify-between items-center">
        <div className="text-white">
          Page {currentPage} of {pageCount}
        </div>
        <div>
          <Button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="mr-2"
          >
            <ChevronLeft className="w-4 h-4" />
          </Button>
          <Button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, pageCount))
            }
            disabled={currentPage === pageCount}
          >
            <ChevronRight className="w-4 h-4" />
          </Button>
        </div>
      </div>
    </div>
  );
}
