import { ArrowRight, BookOpen, Trophy, Users, Zap } from "lucide-react";

export const modules = [
  {
    title: "Getting Started with Blockchain",
    icon: BookOpen,
    items: [
      "What is blockchain technology?",
      "How do cryptocurrencies work?",
      "Why prediction markets are perfect for blockchain.",
    ],
  },
  {
    title: "Introduction to Prediction Markets",
    icon: Zap,
    items: [
      "What are prediction markets?",
      "How do they work?",
      "Benefits of participating in prediction markets.",
    ],
  },
  {
    title: "Making Accurate Predictions",
    icon: Trophy,
    items: [
      "Understanding market trends and events.",
      "How to analyze data for better predictions.",
      "Tips for improving your success rate.",
    ],
  },
  {
    title: "Risk Management in Prediction Markets",
    icon: Users,
    items: [
      "The importance of managing your stakes.",
      "Strategies to minimize losses and maximize gains.",
      "Responsible participation in prediction markets.",
    ],
  },
  {
    title: "Advanced Strategies for Predictions",
    icon: ArrowRight,
    items: [
      "Leveraging historical data for predictions.",
      "Combining prediction skills with crypto knowledge.",
      "Exploring innovative market opportunities.",
    ],
  },
];
