import React from "react";
import { Twitter, Send, Linkedin, Instagram } from "lucide-react";
import { Button } from "./ui/button";

export function Footer() {
  return (
    <footer className="border-t border-white/10 bg-black/40 backdrop-blur-sm py-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center gap-4">
          <div className="text-white/60 text-sm">
            © {new Date().getFullYear()} Crowmarket. All rights reserved.
          </div>
          <div className="flex gap-2">
            <Button
              variant="ghost"
              size="icon"
              className="text-white hover:bg-[#E1B6FF]/10"
            >
              <Twitter className="h-5 w-5" />
              <span className="sr-only">Twitter</span>
            </Button>
            <Button
              variant="ghost"
              size="icon"
              className="text-white hover:bg-[#E1B6FF]/10"
            >
              <Send className="h-5 w-5" />
              <span className="sr-only">Telegram</span>
            </Button>
            <Button
              variant="ghost"
              size="icon"
              className="text-white hover:bg-[#E1B6FF]/10"
            >
              <Linkedin className="h-5 w-5" />
              <span className="sr-only">LinkedIn</span>
            </Button>
            <Button
              variant="ghost"
              size="icon"
              className="text-white hover:bg-[#E1B6FF]/10"
            >
              <Instagram className="h-5 w-5" />
              <span className="sr-only">Instagram</span>
            </Button>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
