import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Leaderboard from "./pages/Leaderboard";
import LiveUpdates from "./pages/LiveUpdates";
import LearnToEarn from "./pages/LearnToEarn";
import Prediction from "./pages/Prediction";

function App() {
  return (
    <div className="min-h-screen bg-[#0D0D0D] flex flex-col">
      <Header />
      <main className="container mx-auto px-4 py-8 flex-grow">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/learn-to-earn" element={<LearnToEarn />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/live-updates" element={<LiveUpdates />} />
          <Route path="/prediction/:id" element={<Prediction />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
