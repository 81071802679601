import {
  Users,
  Bitcoin,
  Code,
  Zap,
  Briefcase,
  BarChart2,
  Cpu,
  Gamepad,
  Scale,
  ShoppingCart,
  PieChart,
  Coins,
  BanknoteIcon as Bank,
  Award,
  Link,
  Network,
  DollarSign,
  PieChartIcon,
  Image,
  Anchor,
  Trophy,
  Dumbbell,
  CircleDot,
} from "lucide-react";

export const predictions = [
  {
    id: 1,
    title: "Will Chromia reach 1 million users by 2025?",
    yesPercentage: 78,
    volume: "2.5M",
    endDate: new Date("2025-12-31"),
    category: "Adoption",
    icon: Users,
    isNew: true,
    isTrending: false,
    tags: ["Chromia", "Crypto"],
  },
  {
    id: 2,
    title: "Bitcoin to surpass $100,000 by end of 2024?",
    yesPercentage: 62,
    volume: "8.7M",
    endDate: new Date("2024-12-31"),
    category: "Price",
    icon: Bitcoin,
    isNew: false,
    isTrending: true,
    tags: ["Crypto"],
  },
  {
    id: 3,
    title: "Chromia to launch 100+ dApps in 2024?",
    yesPercentage: 82,
    volume: "3.2M",
    endDate: new Date("2024-12-31"),
    category: "Development",
    icon: Code,
    isNew: false,
    isTrending: true,
    tags: ["Chromia", "Tech"],
  },
  {
    id: 4,
    title: "Ethereum to implement full sharding by Q2 2025?",
    yesPercentage: 70,
    volume: "6.5M",
    endDate: new Date("2025-06-30"),
    category: "Technology",
    icon: Zap,
    isNew: true,
    isTrending: false,
    tags: ["Crypto", "Tech"],
  },
  {
    id: 5,
    title: "Chromia to partner with a Fortune 500 company?",
    yesPercentage: 30,
    volume: "2.7M",
    endDate: new Date("2024-12-31"),
    category: "Partnership",
    icon: Briefcase,
    isNew: false,
    isTrending: false,
    tags: ["Chromia", "Tech"],
  },
  {
    id: 6,
    title: "Cardano to surpass Ethereum in daily transactions by 2025?",
    yesPercentage: 25,
    volume: "4.1M",
    endDate: new Date("2025-12-31"),
    category: "Adoption",
    icon: BarChart2,
    isNew: false,
    isTrending: false,
    tags: ["Crypto"],
  },
  {
    id: 7,
    title: "Chromia mainnet to process 100k TPS by 2025?",
    yesPercentage: 70,
    volume: "2.3M",
    endDate: new Date("2025-12-31"),
    category: "Technology",
    icon: Cpu,
    isNew: false,
    isTrending: true,
    tags: ["Chromia", "Tech"],
  },
  {
    id: 8,
    title: "Major game studio to adopt Chromia for blockchain game?",
    yesPercentage: 55,
    volume: "3.8M",
    endDate: new Date("2024-12-31"),
    category: "Gaming",
    icon: Gamepad,
    isNew: true,
    isTrending: false,
    tags: ["Chromia", "Tech"],
  },
  {
    id: 9,
    title: "Ripple to win SEC lawsuit by end of 2024?",
    yesPercentage: 68,
    volume: "7.2M",
    endDate: new Date("2024-12-31"),
    category: "Legal",
    icon: Scale,
    isNew: false,
    isTrending: true,
    tags: ["Crypto"],
  },
  {
    id: 10,
    title: "CHR to be accepted as payment on major e-commerce platform?",
    yesPercentage: 25,
    volume: "1.6M",
    endDate: new Date("2025-06-30"),
    category: "Adoption",
    icon: ShoppingCart,
    isNew: false,
    isTrending: false,
    tags: ["Chromia", "Crypto"],
  },
  {
    id: 11,
    title: "Chromia to launch its own DEX by Q2 2024?",
    yesPercentage: 60,
    volume: "3.5M",
    endDate: new Date("2024-06-30"),
    category: "DeFi",
    icon: PieChart,
    isNew: true,
    isTrending: true,
    tags: ["Chromia", "Crypto"],
  },
  {
    id: 12,
    title: "Solana to achieve 1 million daily active users by 2025?",
    yesPercentage: 58,
    volume: "5.3M",
    endDate: new Date("2025-12-31"),
    category: "Adoption",
    icon: Users,
    isNew: false,
    isTrending: false,
    tags: ["Crypto"],
  },
  {
    id: 13,
    title: "Major central bank to pilot CBDC on Chromia?",
    yesPercentage: 18,
    volume: "2.1M",
    endDate: new Date("2025-12-31"),
    category: "CBDC",
    icon: Coins,
    isNew: false,
    isTrending: false,
    tags: ["Chromia", "Crypto", "Tech"],
  },
  {
    id: 14,
    title: "Ethereum to flip Bitcoin in market cap by 2026?",
    yesPercentage: 40,
    volume: "9.8M",
    endDate: new Date("2026-12-31"),
    category: "Market Cap",
    icon: Bank,
    isNew: false,
    isTrending: true,
    tags: ["Crypto"],
  },
  {
    id: 15,
    title: "Chromia to launch cross-chain bridge with 5+ blockchains?",
    yesPercentage: 68,
    volume: "3.3M",
    endDate: new Date("2024-12-31"),
    category: "Interoperability",
    icon: Link,
    isNew: true,
    isTrending: false,
    tags: ["Chromia", "Tech"],
  },
  {
    id: 16,
    title: "Polkadot to have 100+ parachains by end of 2024?",
    yesPercentage: 72,
    volume: "4.7M",
    endDate: new Date("2024-12-31"),
    category: "Development",
    icon: Network,
    isNew: false,
    isTrending: false,
    tags: ["Crypto", "Tech"],
  },
  {
    id: 17,
    title: "CHR to be listed on Coinbase by Q4 2024?",
    yesPercentage: 55,
    volume: "2.1M",
    endDate: new Date("2024-12-31"),
    category: "Exchange",
    icon: DollarSign,
    isNew: false,
    isTrending: true,
    tags: ["Chromia", "Crypto"],
  },
  {
    id: 18,
    title: "Bitcoin dominance to fall below 40% by 2025?",
    yesPercentage: 45,
    volume: "7.5M",
    endDate: new Date("2025-12-31"),
    category: "Market Share",
    icon: PieChartIcon,
    isNew: false,
    isTrending: false,
    tags: ["Crypto"],
  },
  {
    id: 19,
    title: "Major NFT marketplace to launch on Chromia?",
    yesPercentage: 75,
    volume: "2.8M",
    endDate: new Date("2024-12-31"),
    category: "NFT",
    icon: Image,
    isNew: true,
    isTrending: false,
    tags: ["Chromia", "Crypto", "Tech"],
  },
  {
    id: 20,
    title: "Tether (USDT) to lose its position as top stablecoin by 2025?",
    yesPercentage: 35,
    volume: "6.2M",
    endDate: new Date("2025-12-31"),
    category: "Stablecoins",
    icon: Anchor,
    isNew: false,
    isTrending: false,
    tags: ["Crypto"],
  },
  {
    id: 21,
    title: "Will Manchester City win the Champions League in 2024?",
    yesPercentage: 65,
    volume: "5.1M",
    endDate: new Date("2024-06-01"),
    category: "Sports",
    icon: Trophy,
    isNew: true,
    isTrending: true,
    tags: ["Sports"],
  },
  {
    id: 22,
    title: "NFL to adopt blockchain for player contracts by 2025?",
    yesPercentage: 40,
    volume: "3.8M",
    endDate: new Date("2025-12-31"),
    category: "Sports",
    icon: Dumbbell,
    isNew: false,
    isTrending: false,
    tags: ["Sports", "Tech"],
  },
  {
    id: 23,
    title: "Novak Djokovic to win Wimbledon 2024?",
    yesPercentage: 55,
    volume: "4.2M",
    endDate: new Date("2024-07-14"),
    category: "Sports",
    icon: CircleDot,
    isNew: false,
    isTrending: true,
    tags: ["Sports"],
  },
];
