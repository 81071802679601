import React, { useState } from "react";
import { PredictionCard } from "../components/PredictionCard";
import { predictions } from "../data/predictions";
import {
  Users,
  Bitcoin,
  Code,
  Zap,
  Briefcase,
  BarChart2,
  Cpu,
  Gamepad,
  Scale,
  ShoppingCart,
  PieChart,
  Coins,
  BanknoteIcon as Bank,
  Award,
  Link as LinkIcon,
  Network,
  DollarSign,
  PieChartIcon,
  Image,
  Anchor,
  Trophy,
  Dumbbell,
  CircleDot,
} from "lucide-react";
import { Button } from "../components/ui/button";

export default function Home() {
  const [activeFilter, setActiveFilter] = useState("All");

  const filterPredictions = (filter) => {
    switch (filter) {
      case "All":
        return predictions;
      case "New":
        return predictions.filter((p) => p.isNew);
      case "Trending":
        return predictions.filter((p) => p.isTrending);
      case "Chromia":
      case "Crypto":
      case "Tech":
      case "Sports":
        return predictions.filter((p) => p.tags.includes(filter));
      default:
        return predictions;
    }
  };

  const filteredPredictions = filterPredictions(activeFilter);

  return (
    <div className="space-y-8 mb-8">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        {/* Feature Box 1 */}
        <div className="relative overflow-hidden rounded-xl bg-gradient-to-r from-pink-500 to-purple-600 p-6 text-white group hover:shadow-lg transition-all duration-200">
          <div className="relative z-10">
            <h3 className="text-xl font-bold mb-2">Latest Market Updates</h3>
            <p className="text-sm mb-4">
              Stay informed with real-time market movements
            </p>
            <Button
              variant="secondary"
              className="bg-white/20 hover:bg-white/30 text-white"
            >
              View Now
            </Button>
          </div>
          <div className="absolute right-0 bottom-0 opacity-20 transition-opacity group-hover:opacity-30">
            <BarChart2 className="w-32 h-32" />
          </div>
        </div>

        {/* Feature Box 2 */}
        <div className="relative overflow-hidden rounded-xl bg-gradient-to-r from-blue-500 to-cyan-600 p-6 text-white group hover:shadow-lg transition-all duration-200">
          <div className="relative z-10">
            <h3 className="text-xl font-bold mb-2">Trending Predictions</h3>
            <p className="text-sm mb-4">Explore the most active markets</p>
            <Button
              variant="secondary"
              className="bg-white/20 hover:bg-white/30 text-white"
            >
              Discover
            </Button>
          </div>
          <div className="absolute right-0 bottom-0 opacity-20 transition-opacity group-hover:opacity-30">
            <Trophy className="w-32 h-32" />
          </div>
        </div>

        {/* Feature Box 3 */}
        <div className="relative overflow-hidden rounded-xl bg-gradient-to-r from-purple-500 to-indigo-600 p-6 text-white group hover:shadow-lg transition-all duration-200">
          <div className="relative z-10">
            <h3 className="text-xl font-bold mb-2">Market Analysis</h3>
            <p className="text-sm mb-4">Deep dive into market statistics</p>
            <Button
              variant="secondary"
              className="bg-white/20 hover:bg-white/30 text-white"
            >
              Analyze
            </Button>
          </div>
          <div className="absolute right-0 bottom-0 opacity-20 transition-opacity group-hover:opacity-30">
            <PieChart className="w-32 h-32" />
          </div>
        </div>

        {/* Feature Box 4 */}
        <div className="relative overflow-hidden rounded-xl bg-gradient-to-r from-emerald-500 to-teal-600 p-6 text-white group hover:shadow-lg transition-all duration-200">
          <div className="relative z-10">
            <h3 className="text-xl font-bold mb-2">Developer Docs</h3>
            <p className="text-sm mb-4">
              Access comprehensive documentation for developers
            </p>
            <Button
              variant="secondary"
              className="bg-white/20 hover:bg-white/30 text-white"
            >
              Explore Docs
            </Button>
          </div>
          <div className="absolute right-0 bottom-0 opacity-20 transition-opacity group-hover:opacity-30">
            <Code className="w-32 h-32" />
          </div>
        </div>
      </div>

      <div className="flex justify-center space-x-2 mb-6 flex-wrap">
        {["All", "New", "Trending", "Chromia", "Crypto", "Tech", "Sports"].map(
          (filter) => (
            <button
              key={filter}
              onClick={() => setActiveFilter(filter)}
              className={`px-4 py-2 rounded-md ${
                activeFilter === filter
                  ? "bg-[#E1B6FF] text-black"
                  : "bg-black/40 text-white"
              } transition-colors duration-200 mb-2`}
            >
              {filter}
            </button>
          )
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {filteredPredictions.map((prediction) => (
          <PredictionCard key={prediction.id} {...prediction} />
        ))}
      </div>
    </div>
  );
}
