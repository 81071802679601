import React from "react";
import { useParams } from "react-router-dom";
import PredictionDetail from "../components/PredictionDetail";
import {
  Bitcoin,
  Users,
  Code,
  Zap,
  Briefcase,
  BarChart2,
  Cpu,
  Gamepad,
  Scale,
  ShoppingCart,
  PieChart,
  Coins,
  BanknoteIcon as Bank,
  Award,
  Link,
  Network,
  DollarSign,
  PieChartIcon,
  Image,
  Anchor,
} from "lucide-react";
import { subDays, format } from "date-fns";

const icons = {
  Bitcoin,
  Users,
  Code,
  Zap,
  Briefcase,
  BarChart2,
  Cpu,
  Gamepad,
  Scale,
  ShoppingCart,
  PieChart,
  Coins,
  Bank,
  Award,
  Link,
  Network,
  DollarSign,
  PieChartIcon,
  Image,
  Anchor,
};

const generateSentimentData = (days) => {
  return Array.from({ length: days }, (_, i) => ({
    date: format(subDays(new Date(), days - i - 1), "MMM dd"),
    bullish: Math.floor(Math.random() * 50) + 20,
    bearish: Math.floor(Math.random() * 40) + 10,
    neutral: Math.floor(Math.random() * 30) + 10,
  }));
};

const generateVolumeData = (days) => {
  return Array.from({ length: days }, (_, i) => ({
    date: format(subDays(new Date(), days - i - 1), "MMM dd"),
    volume: Math.floor(Math.random() * 10000) + 1000,
  }));
};

// This would typically come from an API or database
const getPredictionData = (id) => {
  // Mock data for demonstration
  return {
    id: parseInt(id),
    title: "Will Bitcoin reach $100,000 by end of 2024?",
    category: "Price",
    yesPercentage: 62,
    volume: "8.7M CHR",
    endDate: new Date("2024-12-31"),
    description:
      "This market predicts whether the price of Bitcoin (BTC) will reach or exceed $100,000 USD at any point before December 31, 2024, 23:59:59 UTC. The outcome will be determined using the volume-weighted average price (VWAP) across major exchanges.",
    trades: [
      {
        id: 1,
        type: "buy",
        amount: "1000 CHR",
        price: "0.62 CHR",
        timestamp: "2023-06-10 14:30:00",
      },
      {
        id: 2,
        type: "sell",
        amount: "500 CHR",
        price: "0.61 CHR",
        timestamp: "2023-06-10 14:15:00",
      },
      {
        id: 3,
        type: "buy",
        amount: "2000 CHR",
        price: "0.63 CHR",
        timestamp: "2023-06-10 14:00:00",
      },
      {
        id: 4,
        type: "sell",
        amount: "800 CHR",
        price: "0.62 CHR",
        timestamp: "2023-06-10 13:45:00",
      },
      {
        id: 5,
        type: "buy",
        amount: "1500 CHR",
        price: "0.61 CHR",
        timestamp: "2023-06-10 13:30:00",
      },
    ],
    icon: icons.Bitcoin,
    sentimentData: generateSentimentData(7),
    volumeData: generateVolumeData(14),
  };
};

function Prediction() {
  const { id } = useParams();
  const predictionData = getPredictionData(id);

  return (
    <div className="container mx-auto px-4 py-8">
      <PredictionDetail {...predictionData} />
    </div>
  );
}

export default Prediction;
