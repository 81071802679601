// import React, { useState } from "react";
// import { motion } from "framer-motion";
// import { Card } from "./ui/card";
// import { Progress } from "./ui/progress";
// import { Link } from "react-router-dom";

// const categoryColors = {
//   Adoption: "#FF6B6B",
//   Exchange: "#4ECDC4",
//   Development: "#45B7D1",
//   Price: "#FFA07A",
//   Partnership: "#98D8C8",
//   Corporate: "#F7B801",
//   Technology: "#7FDBFF",
//   Gaming: "#FF85EA",
//   Integration: "#82B1FF",
//   DeFi: "#B388FF",
//   Staking: "#FF8A65",
//   CBDC: "#FFD54F",
//   Events: "#AED581",
//   Interoperability: "#4DB6AC",
//   Government: "#90A4AE",
//   NFT: "#FF80AB",
//   Education: "#81C784",
//   Legal: "#BA68C8",
//   "Market Cap": "#64B5F6",
//   "Market Share": "#4DB6AC",
//   Stablecoins: "#FFD54F",
// };

// export function PredictionCard({
//   id,
//   title,
//   yesPercentage,
//   volume,
//   endDate,
//   category,
//   icon: Icon,
//   isNew,
//   isTrending,
// }) {
//   const [isHovered, setIsHovered] = useState(false);
//   const categoryColor = categoryColors[category] || "#E1B6FF";

//   return (
//     <Link to={`/prediction/${id}`}>
//       <motion.div
//         whileHover={{ scale: 1.05 }}
//         onHoverStart={() => setIsHovered(true)}
//         onHoverEnd={() => setIsHovered(false)}
//       >
//         <Card className="p-4 bg-black/40 border-white/10 rounded-xl overflow-hidden relative">
//           {isHovered && (
//             <motion.div
//               className="absolute inset-0"
//               style={{ backgroundColor: `${categoryColor}20` }}
//               initial={{ opacity: 0 }}
//               animate={{ opacity: 1 }}
//               exit={{ opacity: 0 }}
//             />
//           )}
//           <div className="relative z-10">
//             <div className="flex justify-between items-start mb-2">
//               <div className="flex items-center gap-2">
//                 <div
//                   className="p-2 rounded-full"
//                   style={{ backgroundColor: categoryColor }}
//                 >
//                   <Icon className="w-6 h-6 text-black" />
//                 </div>
//                 <h3 className="text-lg font-semibold text-white">{title}</h3>
//               </div>
//               <div className="flex flex-col items-end">
//                 <span
//                   className="text-xs font-medium px-2 py-1 rounded-full mb-1"
//                   style={{ backgroundColor: categoryColor, color: "#000000" }}
//                 >
//                   {category}
//                 </span>
//                 {isNew && (
//                   <span className="text-xs font-medium px-2 py-1 rounded-full bg-green-500 text-white">
//                     New
//                   </span>
//                 )}
//                 {isTrending && (
//                   <span className="text-xs font-medium px-2 py-1 rounded-full bg-yellow-500 text-black mt-1">
//                     Trending
//                   </span>
//                 )}
//               </div>
//             </div>
//             <div className="space-y-2 mb-4">
//               <Progress
//                 value={yesPercentage}
//                 className="bg-white/5"
//                 indicatorClassName="bg-[#E1B6FF]"
//               />
//               <div className="flex justify-between text-sm text-white/60">
//                 <span>Yes: {yesPercentage}%</span>
//                 <span>No: {100 - yesPercentage}%</span>
//               </div>
//             </div>
//             <div className="flex justify-between text-sm text-white/80">
//               <span>{volume} CHR</span>
//               <span>{endDate.toLocaleDateString()}</span>
//             </div>
//           </div>
//         </Card>
//       </motion.div>
//     </Link>
//   );
// }

// export default PredictionCard;

import React, { useState } from "react";
import { motion } from "framer-motion";
import { Card } from "./ui/card";
import { Progress } from "./ui/progress";
import { Link } from "react-router-dom";

const categoryColors = {
  Adoption: "#FF6B6B",
  Exchange: "#4ECDC4",
  Development: "#45B7D1",
  Price: "#FFA07A",
  Partnership: "#98D8C8",
  Corporate: "#F7B801",
  Technology: "#7FDBFF",
  Gaming: "#FF85EA",
  Integration: "#82B1FF",
  DeFi: "#B388FF",
  Staking: "#FF8A65",
  CBDC: "#FFD54F",
  Events: "#AED581",
  Interoperability: "#4DB6AC",
  Government: "#90A4AE",
  NFT: "#FF80AB",
  Education: "#81C784",
  Legal: "#BA68C8",
  "Market Cap": "#64B5F6",
  "Market Share": "#4DB6AC",
  Stablecoins: "#FFD54F",
};

export function PredictionCard({
  id,
  title,
  yesPercentage,
  volume,
  endDate,
  category,
  icon: Icon,
  isNew,
  isTrending,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const categoryColor = categoryColors[category] || "#E1B6FF";

  return (
    <Link to={`/prediction/${id}`} className="block h-full">
      <motion.div
        whileHover={{ scale: 1.05 }}
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
        className="h-full"
      >
        <Card className="p-4 bg-black/40 border-white/10 rounded-xl overflow-hidden relative h-full flex flex-col justify-between">
          {isHovered && (
            <motion.div
              className="absolute inset-0"
              style={{ backgroundColor: `${categoryColor}20` }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
          )}
          <div className="relative z-10 flex flex-col h-full">
            <div className="flex justify-between items-start mb-2">
              <div className="flex items-center gap-2">
                <div
                  className="p-2 rounded-full"
                  style={{ backgroundColor: categoryColor }}
                >
                  <Icon className="w-6 h-6 text-black" />
                </div>
                <h3 className="text-lg font-semibold text-white line-clamp-2">
                  {title}
                </h3>
              </div>
              <div className="flex flex-col items-end">
                <span
                  className="text-xs font-medium px-2 py-1 rounded-full mb-1"
                  style={{ backgroundColor: categoryColor, color: "#000000" }}
                >
                  {category}
                </span>
                {isNew && (
                  <span className="text-xs font-medium px-2 py-1 rounded-full bg-green-500 text-white">
                    New
                  </span>
                )}
                {isTrending && (
                  <span className="text-xs font-medium px-2 py-1 rounded-full bg-yellow-500 text-black mt-1">
                    Trending
                  </span>
                )}
              </div>
            </div>
            <div className="space-y-2 mb-4 flex-grow">
              <Progress
                value={yesPercentage}
                className="bg-white/5"
                indicatorClassName="bg-[#E1B6FF]"
              />
              <div className="flex justify-between text-sm text-white/60">
                <span>Yes: {yesPercentage}%</span>
                <span>No: {100 - yesPercentage}%</span>
              </div>
            </div>
            <div className="flex justify-between text-sm text-white/80">
              <span>{volume} CHR</span>
              <span>{endDate.toLocaleDateString()}</span>
            </div>
          </div>
        </Card>
      </motion.div>
    </Link>
  );
}

export default PredictionCard;
